<template>
    <tr>
        <td class="mdl-data-table__cell--non-numeric">
            <div class="mask-record">
                <img class="profile-image-employee-record" :src="validatedImage" alt="Identification"/>
            </div>
        </td>
        <td class="mdl-data-table__cell--non-numeric"><span :class="[selectActionTypeClass]">{{ action }}</span></td>
        <td class="mdl-data-table__cell--non-numeric"><span class="action-date">{{ dateTime | moment('ll') }}</span>
        </td>

        <td class="mdl-data-table__cell--non-numeric" v-if="!isEditing">
            <span class="action-time">{{ dateTime | moment('LT')}}</span>
            <button class="edit-btn mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                    @click="isEditing = !isEditing">{{ $t('record-employee-day.edit') }}
            </button>
        </td>

        <td class="mdl-data-table__cell--non-numeric" v-else>
            <vue-timepicker v-model="time" input-class="edit-time" :minute-interval="5"></vue-timepicker>
            <button class="edit-time-red mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                    @click="isEditing = !isEditing">{{ $t('record-employee-day.cancel') }}
            </button>
            <button class="edit-time-red mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                    @click="handleSubmit">{{ $t('shared.save') }}
            </button>
        </td>
        <td class="mdl-data-table__cell--non-numeric">{{ zone }}</td>
        <td class="mdl-data-table__cell--non-numeric">{{ place }}</td>
    </tr>
</template>

<script>
    import {mapActions} from 'vuex'
    import {GetZoneDateTime, StandardizeDateTime, ValidateTime} from '@/services/shared/Utils'
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import moment from 'moment'
    import {Attendance, EMPTY_IMAGE_PROFILE} from "@/services/shared/Attendance"

    export default {
        name: "EmployeeDayTableRowItem",
        data() {
            return {
                isEditing: false,
                time: '',
            }
        },
        props: {
            id: {
                type: String
            },
            image: {
                type: String,
            },
            action: {
                type: String
            },
            date: {
                type: String
            },
            place: {
                type: String
            },
            geo: {
                type: String
            }
        },
        created() {
            this.time = moment(this.date).format("HH:mm")
        },
        computed: {
            validatedImage() {
                return this.image !== '' ? this.image : EMPTY_IMAGE_PROFILE
            },
            dateTime() {
                return StandardizeDateTime(this.date)
            },
            zone() {
                return GetZoneDateTime(this.date)
            },
            selectActionTypeClass() {
                if (this.action === Attendance.type.IN) return "action in"
                if (this.action === Attendance.type.OUT) return "action out"
                if (this.action === Attendance.type.PAUSE) return "action pause"
                if (this.action === Attendance.type.RESUME) return "action resume"
                return "action"
            }
        },
        methods: {
            ...mapActions('record', ['updateAttendanceTime']),
            async handleSubmit() {
                if (!ValidateTime(this.time)) {
                    this.$toast.error(this.$i18n.t('record-employee-day.invalid-time'))
                    return
                }

                try {
                    let hours = this.time.split(":")[0],
                        minutes = this.time.split(":")[1];
                    await this.updateAttendanceTime({
                        employeeId: this.$route.params['employeeId'],
                        recordId: this.$route.params['recordId'],
                        actionId: this.id,
                        createdAt: moment.parseZone(this.dateTime).hours(hours).minute(minutes).format()
                    })
                    this.$toast.success(this.$i18n.t('record-employee-day.edit-successful'))
                    this.isEditing = false;
                } catch (error) {
                    this.$toast.error(this.$i18n.t('record-employee-day.edit-error'))
                }
            }
        },
        components: {
            VueTimepicker
        }
    }
</script>

<style scope lang="scss">
    .edit-btn {
        font-size: 0.9em;
        padding: 0 10px;
        margin: 0 10px;

        &:hover {
            background-color: rgb(88, 88, 88);
            color: #000;
        }
    }

    .edit-time-red {
        font-size: 0.9em;
        padding: 0 10px;
        margin: 0 10px;
        color: #000;

        &:hover {
            background-color: #ff0000;
            color: #000;
        }
    }

    .edit-time {
        border: 1px solid #ff0000;
    }
</style>
