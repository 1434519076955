<template>
    <div class="page-content">

        <section-title :title="title" :name="fullName"/>

        <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--2-col-tablet mdl-cell--1-col-phone">
                <router-link class="mdl-navigation__link back-list"
                             :to="{ name: 'EmployeeRecord', params: {employeeId: this.employeeId} }">
                    <i class="material-icons">keyboard_arrow_left</i> {{ $t('shared.back') }}
                </router-link>
            </div>

            <div v-if="allowOut" class="mdl-cell mdl-cell--8-col mdl-cell--6-col-tablet mdl-cell--3-col-phone">
                <div class="add-row-wrapper">
                    <span v-if="!isAddingRow">
                        <button class="edit-btn mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                                @click="isAddingRow = !isAddingRow">{{ $t('record-employee-day.add-out') }}</button>
                    </span>

                    <span v-else>
                        <vue-timepicker v-model="time" input-class="input-out-time" :minute-interval="5"></vue-timepicker>
                        <button class="add mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                                @click="isAddingRow = !isAddingRow">{{ $t('record-employee-day.cancel') }}</button>
                        <button class="add mdl-button mdl-button--colored mdl-button--raised mdl-js-button mdl-js-ripple-effect"
                                @click="handleAdd">{{ $t('record-employee-day.add') }}</button>
                    </span>
                </div>
            </div>
        </div>

        <div class="mdl-grid">
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
            <record-day-table :record="record"/>
        </div>
    </div>
</template>

<style scope lang="scss">
    .add-row-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .add {
        margin-left: 12px;
    }

    .input-out-time {
        height: 35px;
    }

    .input-out-time:focus {
        border: 1px solid red;
    }
    .vue__time-picker .dropdown {
        position: relative;
        z-index: 1000;
        top: 0;
    }
</style>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import SectionTitle from "@/components/shared/SectionTitle"
    import VueTimepicker from 'vue2-timepicker'
    import VueElementLoading from 'vue-element-loading'
    import RecordDayTable from '@/components/record/RecordDayTable'
    import {ValidateTime} from "@/services/shared/Utils"
    import {Attendance} from "@/services/shared/Attendance"
    import moment from "moment"

    export default {
        name: 'EmployeeRecordDay',
        props: {
            employeeId: {
                type: String,
            },
            recordId: {
                type: String,
            },
        },
        created() {
            this.loading()
        },
        data() {
            return {
                record: {},
                title: this.$i18n.t('record-employee-day.title'),
                isAddingRow: false,
                time: '',
            }
        },
        computed: {
            ...mapState({
                show: state => state.record.requesting,
                records: state => state.record.employeeRecords
            }),
            ...mapGetters('record', ['getRecordById']),
            ...mapGetters('employee', ['getEmployeeById']),
            fullName() {
                const employee = this.getEmployeeById(this.employeeId)
                if (employee === undefined)
                    return ""
                return `${employee.name} ${employee.surname}`
            },
            allowOut() {
                return Object.keys(this.record).length % 2 !== 0;
            },
        },
        methods: {
            ...mapActions('record', ['createAttendanceTime']),
            onChangePage(pageOfItems) {
                this.pageOfItems = pageOfItems;
            },
            async goToEmployeeList() {
                await this.$router.push({name: 'EmployeeList'})
            },
            async handleAdd() {
                if (!ValidateTime(this.time)) {
                    this.$toast.error(this.$i18n.t('record-employee-day.invalid-time'))
                    return
                }
                try {
                    let hours = this.time.split(":")[0],
                        minutes = this.time.split(":")[1]
                    await this.createAttendanceTime({
                        employeeId: this.employeeId,
                        recordId: this.recordId,
                        createdAt: moment.parseZone(this.record[0].createdAt).hours(hours).minute(minutes).format(),
                        actionType: Attendance.type.OUT
                    })
                    this.$toast.success(this.$i18n.t('record-employee-day.add-successful'))
                    this.isAddingRow = false
                    this.loading()
                } catch (error) {
                    this.$toast.error(this.$i18n.t('record-employee-day.add-error'))
                }
            },
            loading() {
                this.record = Object.assign({}, this.getRecordById(this.recordId))
                if (Object.keys(this.record).length === 0) {
                    this.goToEmployeeList()
                }
            }
        },
        components: {
            RecordDayTable,
            SectionTitle,
            VueElementLoading,
            VueTimepicker
        }
    }

</script>
