<template>
    <div id="wrapper-table">
        <table id="table-employees" class="mdl-data-table mdl-js-data-table mdl-shadow--2dp">
            <thead>
            <tr>
                <th id="photo" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.profile') }}</th>
                <th id="action" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.action') }}</th>
                <th id="date" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.date') }}</th>
                <th id="time" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.time') }}</th>
                <th id="zone" class="mdl-data-table__cell--non-numeric">{{ $t('shared.timezone') }}</th>
                <th id="gps" class="mdl-data-table__cell--non-numeric">{{ $t('list-employee-record.placemark') }}</th>
            </tr>
            </thead>
            <tbody>
            <record-day-table-row-item
                    v-for="action in record"
                    :key="action.actionId"
                    :id="action.actionId"
                    :action="action.actionType"
                    :date="action.createdAt"
                    :place="action.placeMark"
                    :image="action.image"
                    :geo="action.geo"
            ></record-day-table-row-item>
            </tbody>
        </table>
    </div>
</template>

<script>
    import RecordDayTableRowItem from "@/components/record/RecordDayTableRowItem";

    export default {
        name: "RecordActionTable",
        props: ['record'],
        components: {
            RecordDayTableRowItem,
        },
    }
</script>
